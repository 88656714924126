import request from '@/utils/request'


// 查询用户道具列表
export function listProp(query) {
  return request({
    url: '/user/user-prop/list',
    method: 'get',
    params: query
  })
}

// 查询用户道具分页
export function pageProp(query) {
  return request({
    url: '/user/user-prop/page',
    method: 'get',
    params: query
  })
}

// 查询用户道具详细
export function getProp(data) {
  return request({
    url: '/user/user-prop/detail',
    method: 'get',
    params: data
  })
}

// 新增用户道具
export function addProp(data) {
  return request({
    url: '/user/user-prop/add',
    method: 'post',
    data: data
  })
}

// 修改用户道具
export function updateProp(data) {
  return request({
    url: '/user/user-prop/edit',
    method: 'post',
    data: data
  })
}

// 删除用户道具
export function delProp(data) {
  return request({
    url: '/user/user-prop/delete',
    method: 'post',
    data: data
  })
}
